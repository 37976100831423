import React from "react";

const FullPageListItem = props => {
    const {className, id, handleFullpageListClick, title} = props;

    return (
        <li
            className={className}
            data-id={id}
            onClick={handleFullpageListClick}
        >
            <span className="list-link">
                {title}
            </span>
        </li>
    )
}

export default FullPageListItem;
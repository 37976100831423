import React, {Component} from "react";
import {Helmet} from "react-helmet-async";
import {withRouter} from "react-router";

class Head extends Component {
    constructor(props) {
        super(props);
        this.state = {
            canonicalUrl: '',
        }
    }

    componentDidMount() {
        this.updateCanonicalUrl()
    }

    componentDidUpdate() {
        this.updateCanonicalUrl()
    }

    updateCanonicalUrl() {
        if (!window.location.host.startsWith('www.')) {
            return;
        }

        const canonicalCandidate = window.location.href.replace('//www.', '//');

        if (canonicalCandidate !== this.state.canonicalUrl) {
            this.setState({ canonicalUrl: canonicalCandidate })
        }
    }

    render ()  {
        return (
            <Helmet>
                { Boolean(this.state.canonicalUrl) && <link rel="canonical" href={this.state.canonicalUrl}/> }
            </Helmet>
        )
    }
}

export default withRouter(Head)
import React, { Component } from "react";

import "./mightwork.scss";
import aboutUsData from "../../containers/AboutUsPage/AboutUsData"


class MightWork extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    renderAboutInfo(direction, src, caption, keyItem, list) {
        return (
            <li key={keyItem} className={direction}>
                <div className="might-work-block-copy">
                    <span className="might-work-icon">
                        <img src={src} alt={caption}/>
                    </span>
                    {this.renderNestedListItems(caption, list)}

                </div>
            </li>
        );
    }

    renderNestedListItems(mainCaption, list) {

        if(list) {
            return Array.isArray(list) && list.length && (
                <div className="might-work-copy">
                    <p className="text-bold">{mainCaption}</p>
                    <ul className="might-work-nested-list">
                        {list.map(({caption}, i) =>
                            <li key={i}><p>{caption}</p></li>
                        )}
                    </ul>
                </div>
            );
        } else {
            return (
                <p>{mainCaption}</p>
            );
        }
    }

    renderListItems(aboutUsData) {
        return <div className="might-work-list">
            <ul>
            {aboutUsData && aboutUsData.map(({src, caption, list}, i) => {
                i++;
                if( i === aboutUsData.length ) {
                    return this.renderAboutInfo("right", src, caption, i, list)
                } else if( i %2 !== 0 ){
                    return this.renderAboutInfo("left", src, caption, i, list)
                } else {
                    return this.renderAboutInfo("right", src, caption, i, list)
                }
            })}
            </ul>
        </div>
    }

    render() {

        return (
          <section className="might-work-section">
              <div className="container">
                  <h3 className="might-work-title">
                    {this.state.data}
                      Why you might want to work with us?
                  </h3>
                  <div className="might-work-article">
                        <span className="might-work-seperate-line"></span>
                      {this.renderListItems(aboutUsData)}
                  </div>
              </div>
          </section>
        );
    }

}

export default MightWork;
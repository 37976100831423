const upgradeData = [
    {
        id: 1,
        title: "Gather information about the upgrade you’re planning and determine what exactly is required",
        anchor: "#gather-information",
        items: [
            {
                id: 1,
                heading: "Determine which customizations to transfer",
            },
            {
                id: 2,
                heading: "Decide on which functionality provided by the extensions should be preserved. You should only transfer those features which are really needed. " +
                    "Do not try to compete with Magento Marketplace in the number of the installed extensions. Every installed extension is a potential source of:",
                list: [
                    {
                        id: 1,
                        label: "issues to be handled"
                    },
                    {
                        id: 2,
                        label: "front-end bits to be adjusted to fit your design flawlessly"
                    },
                    {
                        id: 3,
                        label: "Magento upgrade compatibility issues"
                    },
                    {
                        id: 4,
                        label: "extension upgrade hassle"
                    },
                    {
                        id: 5,
                        label: "additional complexity to take into account during further development (abstraction levels, " +
                            "observers which should be considered further on, additional JS logic, class overrides, adjustments of the database structure)"
                    },
                ]
            },
            {
                id: 3,
                heading: "Consider the CMS content to be kept",
            },
            {
                id: 4,
                heading: "Determine the new look of the store",
                list: [
                    {
                        id: 1,
                        label: "Current store UI will remain (basing on the current look we will create the corresponding M2 layout). " +
                            "Note that most part of the layout will not be reused. Magento 1 and Magento 2 have different HTML and CSS approaches and while reusing the existing base is possible " +
                            "it is strongly not recommended. Additionally, you might want to utilize at least some of the M2 UI/UX features. " +
                            "Transferring design plainly/fully might cut them out requiring additional development work as well as reducing their usability."
                    },
                    {
                        id: 2,
                        label: "New design should be applied. If you’re planning to use a theme as a base for the new UI it's best for the designers to investigate the theme first not to deviate " +
                            "from the concept drastically. Generally, any design could be applied to any theme. However, it is often reasonable to use a standard Magento Luma theme not to add unnecessary " +
                            "additional layer of complexity (\"Hey, make my Ultimo look like Porto\")."
                    },
                    {
                        id: 3,
                        label: "Apply M2 theme with minor modifications. There is a significant amount of good-looking and professionally prepared themes. " +
                            "If you need a quick solution and/or are on a tight budget that might be a good option. Choose the one which generally fits, install, " +
                            "and apply some adjustments not to look too typical."
                    },
                ]
            },
            {
                id: 5,
                heading: "Determine additional assets to be transferred (such as videos, documents, packages, etc.)",
            },
            {
                id: 6,
                heading: "If that's not been done yet you should get familiar with M2 features, UI/UX differences and its new functionality. " +
                    "We could provide a M2 test instance under your full control. Mess it up as you want, we’ve got plenty of them in every pocket.",
            },
        ],
    },
    {
        id: 2,
        title: "Analyze M1 installation to determine:",
        anchor: "#analyze-current-m1",
        items: [
            {
                id: 1,
                heading: "amount of customizations"
            },
            {
                id: 2,
                heading: "state of the database (its integrity, the number of adjustments comparing to the default schema)"
            },
            {
                id: 3,
                heading: "specific front-end behavior"
            },
            {
                id: 4,
                heading: "3rd party integrations"
            },
            {
                id: 5,
                heading: "OS/Software level infrastructure (make sure that the configuration is standard, no URL rewrites in apache/nginx configuration is found)"
            },
        ],
    },
    {
        id: 3,
        title: "Define the planned customizations and extensions’ set",
        anchor: "#plan-customization",
        items: [
            {
                id: 1,
                heading: "Check for M2 versions of the extensions which are mandatory to transfer"
            },
            {
                id: 2,
                heading: "Check for the extensions which don't have direct analogues for M2 and find the ones with similar functionality"
            },
            {
                id: 3,
                heading: "If functionality is required but there is no M2 extension to cover it then determine the subscope of functionality which is really required and the one which " +
                    "could be skipped at least at the initial stages. Thus, the needed one will be implemented directly during the migration while the optional one will be added later."
            },
            {
                id: 4,
                heading: "Investigate all 3rd party integrations. Check if sandbox environments and test accounts are available. " +
                    "Otherwise, prepare a plan for testing which will not affect the existing business processes and will allow to move to production smoothly. " +
                    "Sometimes, you should be careful here as you may have an integration with the shipping provider and they might just pack the test orders and send them with fake emails and names. " +
                    "That actually happens (“Hey, John Doe, hope you have enjoyed your parcel”). Prepare a list of integrations. Write down production and sandbox/test credentials. " +
                    "Along with Magento, they often use such integrations as:",
                list: [
                    {
                        id: 1,
                        label: "analytics scripts"
                    },
                    {
                        id: 2,
                        label: "payment gateways"
                    },
                    {
                        id: 3,
                        label: "shipping gateways"
                    },
                    {
                        id: 4,
                        label: "remarketing scripts"
                    },
                    {
                        id: 5,
                        label: "ERP systems"
                    },
                    {
                        id: 6,
                        label: "CRM systems"
                    },
                    {
                        id: 7,
                        label: "BI systems"
                    },
                    {
                        id: 8,
                        label: "PIM"
                    },
                    {
                        id: 9,
                        label: "external sales channels"
                    },
                    {
                        id: 10,
                        label: "suppliers/inventory providers"
                    },
                    {
                        id: 11,
                        label: "CMS (often WordPress :( )"
                    },
                    {
                        id: 12,
                        label: "\"Login with\" and SSO"
                    },
                    {
                        id: 13,
                        label: "social sharing"
                    },
                ],
            },
        ],
    },
    {
        id: 4,
        title: "Prepare the server",
        anchor: "#prepare-server",
        items: [
            {
                id: 1,
                heading: "Choose the M2 server",
                list: [
                    {
                        id: 1,
                        label: "The staging server should be prepared to test M2 during partial migrations. It could either remain in the development or staging role " +
                            "after going to the production (recommended), or switch to production once everything is set and shining.",
                    },
                    {
                        id: 2,
                        label: "Consider what hosting to choose",
                        sublist: [
                            {
                                id: 1,
                                label: "Shared hosting (not recommended)"
                            },
                            {
                                id: 2,
                                label: "Magento-specialized hostings might be a good option. Often, they work pretty well. However, you will be charged for a \"special label\", sometimes, for doubtful features or " +
                                    "Magento-aware support. Usually they have minimal Magento knowledge but able to handle some of the most common issues (e.g. cache purging or reindexing)."
                            },
                            {
                                id: 3,
                                label: "VPS/VDS. You run things by your own. Probably, that is the most common type of hosting for Magento. " +
                                    "You should make sure the server will run Magento smoothly. The key things to be taken into consideration:",
                                sublist: [
                                    {
                                        id: 1,
                                        label: "Free space. Magento does not use a lot of HDD space by itself. Though, you should store images, uploads, and host database (if you choose to store them on the same server). " +
                                            "So, in most cases, the amount of free space depends on the number/size of images, the size of database, " +
                                            "and the size of cache (especially if you are using some full page or external HTTP cache)"
                                    },
                                    {
                                        id: 2,
                                        label: "RAM. The minimal requirement is 2GB for Magento to function. However, even a relatively small store is recommended to have at least 4GB. " +
                                            "For average size stores 8/16GB must be good. Having a sufficient amount of RAM is vital for the store to maintain decent back-end performance."
                                    },
                                    {
                                        id: 3,
                                        label: "Number of CPU cores. CPU speed is good to have but less important comparing to the number of cores. PHP thread will utilize the CPU core to be executed. " +
                                            "That means more core handles, more PHP threads, and happier clients receiving pages in time."
                                    },
                                    {
                                        id: 4,
                                        label: "SSD. It's good to have, especially on the server where your database is located. While generally you could put most of the application-level stuff into RAM, " +
                                            "it's not likely that the database will fit there so the data will be taken from your HDD/SDD."
                                    },
                                ]
                            },
                            {
                                id: 4,
                                label: "Magento Cloud",
                                sublist: [
                                    {
                                        id: 1,
                                        label: "Amazon infrastructure-based hosting from Magento itself. That’s a good but costly option."
                                    }
                                ]
                            },
                            {
                                id: 5,
                                label: "VPS/VDS-based multi-server configuration. That's a matter of a large size so it will scratch the surface only. If you don't have millions of monthly visitors most probably " +
                                    "you'll be fine with one server. We would recommend to extract \"support\" software to the separate server, however, for small/medium size stores one good server works well too. " +
                                    "In the two-server configuration, it is reasonable to extract caches, session storage, search engine, and reverse proxy. Potentially, next step is to extract the database to be " +
                                    "hosted on the separate server. Still not enough? Put everything behind the load balancer(s) which hits the reverse proxy(ies) which hits the application instances which are going " +
                                    "to the separate RabbitMQ server, separate Elasticsearch, and separate Redis server hitting the database servers split into 3 databases (if you are using EE) which are configured " +
                                    "in Master/Slave mode and add read-only databases. Have fun.",
                            },
                        ]
                    },
                ],
            },
            {
                id: 2,
                heading: "Obtain a SSL certificate",
                list: [
                    {
                        id: 1,
                        label: "You probably have one already issued for your current store. If it is so then it will be reused."
                    },
                    {
                        id: 2,
                        label: "It’s optimal to have one for the staging/development server too (not to play around the hosts file during the development as the URL usually differs). " +
                            "Encrypt works well here, though for production installation you might want to consider an EV certificate."
                    },
                    {
                        id: 3,
                        label: "Consider",
                        sublist: [
                            {
                                id: 1,
                                label: "to put the whole site under HTTPS",
                                sublist: [
                                    {
                                        id: 1,
                                        label: "301 not 302 redirects"
                                    },
                                    {
                                        id: 2,
                                        label: "\"old\" URLs are accessible (if redirection is needed then it's 302)"
                                    },
                                    {
                                        id: 3,
                                        label: "Canonicals are set"
                                    },
                                    {
                                        id: 4,
                                        label: "Replace the links with the direct http references in the CMS content (if there are any) to https or skip the protocol " +
                                            "so that your browser will automatically put the correct one."
                                    },
                                    {
                                        id: 5,
                                        label: "Update your sitemap"
                                    },
                                ]
                            },
                            {
                                id: 2,
                                label: " to enable HSTS"
                            },
                            {
                                id: 3,
                                label: "to enable HTTP/2"
                            },
                            {
                                id: 4,
                                label: "to enable OCSP stapling"
                            },

                        ],
                    },
                ],
            },
            {
                id: 3,
                heading: "Consider using monitoring software",
                list: [
                    {
                        id: 1,
                        label: "Magento supports New Relic integration out of the box. It is good. Mind the costs."
                    },
                    {
                        id: 2,
                        label: "There are free (or mostly free) tools which could cover a part of the functionality provided by New Relic or even " +
                            "full functionality if you have a couple of free months to set things up. Some popular tools are:",
                        sublist: [
                            {
                                id: 1,
                                label: "Nagios"
                            },
                            {
                                id: 2,
                                label: "Zabbix"
                            },
                            {
                                id: 3,
                                label: "Cacti"
                            },
                            {
                                id: 4,
                                label: "Munin"
                            },
                        ]
                    },
                ],
            },
            {
                id: 4,
                heading: "Plan which OS-level software to use",
                list: [
                    {
                        id: 1,
                        label: "Git (if it's planned to provision with it)"
                    },
                    {
                        id: 2,
                        label: "Fail2ban, Rootkit Hunter, or any other favourite security software of yours (important if the staging server goes to production later)"
                    },
                    {
                        id: 3,
                        label: "Libraries needed for Magento"
                    },
                    {
                        id: 4,
                        label: "SSH/SFTP/FTP access"
                    },
                    {
                        id: 5,
                        label: "\"Magento-related\" software: a web server, a database server, Varnish, cache, PHP, PHP libraries, a composer, etc."
                    },
                ],
            },
        ],
    },
    {
        id: 5,
        title: "Basing on the analysis given above, prepare a plan with a task breakdown.",
        anchor: "#prepare-plan",
        items: [
            {
                id: 1,
                heading: "Outline the roles (responsible parties) to cover such aspects as :",
                list: [
                    {
                        id: 1,
                        label: "general coordination"
                    },
                    {
                        id: 2,
                        label: "design preparation/validation"
                    },
                    {
                        id: 3,
                        label: "intermediate QA (that is never fully covered on our side, the customer should always confirm that the functionality scope is covered as expected at least on key phases)"
                    },
                    {
                        id: 4,
                        label: "configuration and content entry"
                    },
                ],
            },
            {
                id: 2,
                heading: "A more detailed plan is better but it should be reasonably detailed otherwise it will be an unmanageable pile of tasks."
            },
            {
                id: 3,
                heading: "Next tasks should be estimated. That will give you the understanding of some level the work should be processed at, so there will be an idea of the potential " +
                    "budget and the expected timeframe. Usually, an average separate task size for a small/mid-scale project is 2-6h. On the one hand, that does not produce billions of tasks. " +
                    "On the other hand, that allows to have regular updates on the progress (e.g. on the average, 1-4 tasks are covered on a daily basis by one developer working full time)."
            },
            {
                id: 4,
                heading: "All is set for the start but you need to confirm:",
                list: [
                    {
                        id: 1,
                        label: "that the prepared plan is",
                        sublist: [
                            {
                                id: 1,
                                label: "consistent"
                            },
                            {
                                id: 2,
                                label: "nothing is missed"
                            },
                            {
                                id: 3,
                                label: "tasks estimation looks reasonable"
                            },
                        ]
                    },
                    {
                        id: 2,
                        label: "the expected delivery date",
                    },
                    {
                        id: 3,
                        label: "cooperation terms",
                    },
                ],
            },
        ],
    },
    {
        id: 6,
        title: "Deploy the staging",
        anchor: "#staging-deployment",
        items: [
            {
                id: 1,
                heading: "Latest Magento 2 version is deployed to staging, including the sample data. It will be needed for testing extensions' functionality on \"clean\" Magento"
            },
            {
                id: 2,
                heading: "Theme is applied (if needed)"
            },
            {
                id: 3,
                heading: "Required extensions are installed",
                list: [
                    {
                        id: 1,
                        label: "Check if the migration tool runs well after the target DB adjustment"
                    }
                ]
            },
            {
                id: 4,
                heading: "Crawling is disabled (front-end access restricted by IP or hidden behind the basic authentication)"
            },
        ],
    },
    {
        id: 7,
        title: "Transfer the data and functionality",
        anchor: "#transfer-data",
        items: [
            {
                id: 1,
                heading: "Handle extensions' compatibility issues, check that functionality works on the sample data. Handle major functionality issues and theme conflicts"
            },
            {
                id: 2,
                heading: "Wipe the database (remove the sample data)"
            },
            {
                id: 3,
                heading: "Run the Magento migration tool"
            },
            {
                id: 4,
                heading: "Gather a list of errors which are related to DB structure difference"
            },
            {
                id: 5,
                heading: "Basing on the error list and the DB difference analysis, give additional handling:",
                list: [
                    {
                        id: 1,
                        label: "to input DB constraints and FK violations"
                    },
                    {
                        id: 2,
                        label: "to DB structure changes"
                    },
                    {
                        id: 3,
                        label: "to data-level changes (e.g. the extensions' logic has been adjusted so the data should be transformed to correspond it)"
                    },
                    {
                        id: 4,
                        label: "to data relation adjustments"
                    },
                ]
            },
            {
                id: 6,
                heading: "Dry run migration tool to collect errors/warnings",
                list: [
                    {
                        id: 1,
                        label: "check if it fails on first warning or processes the whole batch producing meaningful log"
                    },
                ]
            },
            {
                id: 7,
                heading: "Migrate/fix/repeat until a clear M2 database structure will be obtained"
            },
            {
                id: 8,
                heading: "Perform the surface testing to eliminate data migration issues, check that:",
                list: [
                    {
                        id: 1,
                        label: "main workflows work properly"
                    },
                    {
                        id: 2,
                        label: "extensions work properly"
                    },
                    {
                        id: 3,
                        label: "you’ve transferred",
                        sublist: [
                            {
                                id: 1,
                                label: "configuration settings"
                            },
                            {
                                id: 2,
                                label: "CMS data, and"
                            },
                            {
                                id: 3,
                                label: "email templates correctly"
                            },
                        ]
                    },
                ]
            },
            {
                id: 9,
                heading: "Handle issues found on the previous step, check/handle the delta migration"
            },
            {
                id: 10,
                heading: "Move the static assets (media folder, uploads, etc.)"
            },
            {
                id: 11,
                heading: "Fix major front-end issues which block the development and the testing (e.g. JS issues)"
            },
            {
                id: 12,
                heading: "At this stage, you should have more or less functional yet not ready installation"
            },
            {
                id: 13,
                heading: "Transfer the needed back-end customizations, recheck that customizations are working. " +
                    "If the database adjustments are needed update the migration tool to be able to handle the transfer smoothly"
            },
            {
                id: 14,
                heading: "Apply design and front-end functionality"
            },
            {
                id: 15,
                heading: "Install SSL"
            },
            {
                id: 16,
                heading: "Set up the needed redirects and force SSL if needed"
            },
            {
                id: 17,
                heading: "Transfer any analytics and external scripts operating from the front-end (GA, GTM, HotJar, Rejoiner, Segment, etc). " +
                    "It's best to use either sandbox accounts or those ones created for testing"
            },
            {
                id: 18,
                heading: "Transfer all other 3rd party integrations (make sure they will not affect the current installation and will not mess up the existing data)."
            },
            {
                id: 19,
                heading: "Perform the front-end and back-end optimization (if needed)",
                list: [
                    {
                        id: 1,
                        label: "Turn off caching and check that TTFB is acceptable on key pages. At least check:",
                        sublist: [
                            {
                                id: 1,
                                label: "home page"
                            },
                            {
                                id: 2,
                                label: "category page"
                            },
                            {
                                id: 3,
                                label: "product page"
                            },
                            {
                                id: 4,
                                label: "run search"
                            },
                            {
                                id: 5,
                                label: "go through checkout process"
                            },
                            {
                                id: 6,
                                label: "\"my account page\""
                            },
                        ]
                    },
                    {
                        id: 2,
                        label: "Perform the back-end profiling and optimization if any issues are found in the previous step"
                    },
                    {
                        id: 3,
                        label: "Use the tools which are measuring the front-end optimization level (e.g. Google Page Speed, GTmetrix). It is recommended to optimize up to some reasonable level. " +
                            "Magento could be optimized to score nearly \"A\"/100% but starting from some point it becomes quite time-consuming and does not make any noticeable profit. " +
                            "Additionally, some aspects of \"full\" optimization will bring pain in the neck during the functionality adjustments and version upgrades."
                    },
                    {
                        id: 4,
                        label: "Process w3c validation at least for key pages"
                    },
                ]
            },
            {
                id: 20,
                heading: "Things to check and handle if there are any issues:",
                list: [
                    {
                        id: 1,
                        label: "reindex should be run smoothly"
                    },
                    {
                        id: 2,
                        label: "you should be able to switch installation to the production mode without any issues"
                    },
                    {
                        id: 3,
                        label: "page cache should not exceed and should be functional"
                    },
                ]
            },
            {
                id: 21,
                heading: "If no-downtime migration is preferable set up and check that no issues will be caused by increasing increment " +
                    "IDs which are likely to be increased during the switch between the M1 prod and the M2 prod. Usually, these are User, Order, Invoice, Shipping, and Quote IDs. " +
                    "That is important not to overlap entities’ IDs during the delta migration."
            },
            {
                id: 22,
                heading: "Make sure all SEO aspects are settled:",
                list: [
                    {
                        id: 1,
                        label: "sitemap is generated"
                    },
                    {
                        id: 2,
                        label: "robots.txt is valid"
                    },
                    {
                        id: 3,
                        label: "meta information is set properly"
                    },
                ]
            },
        ],
    },
    {
        id: 8,
        title: "Check the staging server fully",
        anchor: "#test-staging-server",
        items: [
            {
                id: 1,
                heading: "emails are sent properly"
            },
            {
                id: 2,
                heading: "all workflows are functional"
            },
            {
                id: 3,
                heading: "taxes are configured properly"
            },
            {
                id: 4,
                heading: "flat tables are turned on and functioning"
            },
            {
                id: 5,
                heading: "all traces of the default configuration are eliminated (e.g. email addresses, copyright, and favicon have been adjusted either)"
            },
            {
                id: 6,
                heading: "payment gateways are set (final check is optimal to perform, by placing a \"real\" purchase on some test product with a low price)"
            },
            {
                id: 7,
                heading: "email templates are configured"
            },
            {
                id: 8,
                heading: "shipping methods are configured"
            },
            {
                id: 9,
                heading: "there are no SSL \"mixed content\" warnings",
            },
            {
                id: 10,
                heading: "PHP log level in increased to maximum and there are no errors in:",
                list: [
                    {
                        id: 1,
                        label: "JS console",
                    },
                    {
                        id: 2,
                        label: "web server logs (Apache/Nginx)",
                    },
                    {
                        id: 3,
                        label: "Magento",
                    },
                    {
                        id: 4,
                        label: "any additional applications used (Redis/Memcache/Elasticsearch/ RabbitMQ/Varnish)",
                    },
                    {
                        id: 5,
                        label: "OS-level logs",
                    },
                ]
            },
            {
                id: 11,
                heading: "page cache is turned on and functioning. To properly test that cache does not affect extensions and customizations, " +
                    "it might be needed to check the site taking into account that the front-end data could be cached (e.g. you’re testing that you can log in as John, and Magento " +
                    "shows \"Hello, John\" to you. But you also need to check that when you log in as Jane it won’t read \"Hello, John\")"
            },            {
                id: 12,
                heading: "recheck data migration (from scratch) and delta migration. Basic delta migration check is to register a new user on an old server and process the order. " +
                    "Run the delta migration, check on the M2 server that the user has appeared and the order has been placed."
            },            {
                id: 13,
                heading:  "check all 3rd party integrations"
            },
        ]
    },
    {
        id: 9,
        title: "Prepare staging for moving to production or transfer installation to the production server",
        anchor: "#prepare-production",
        items: [
            {
                id: 1,
                heading: "If installation is transferred (production will use a separate server) then steps similar to the staging server deployment should be performed"
            },
            {
                id: 2,
                heading: "Lower TTL values for the current domain name (once you switch the records the IP address will propagate faster)"
            },
            {
                id: 3,
                heading: "Stop any actions in administration area on the M1 server (it’s best to disable the login to the admin area at all)"
            },
            {
                id: 4,
                heading: "Run the data migration, move images and assets"
            }
        ]
    },
    {
        id: 10,
        title: "Check the production server, make sure that",
        anchor: "#test-production",
        items: [
            {
                id: 1,
                heading: "All steps from \"Fully check staging server\" are checked and valid"
            },
            {
                id: 2,
                heading: "SSL for production URL is installed/works properly (can be done by adjusting hosts file to override DNS)"
            },
            {
                id: 3,
                heading: "outgoing email headers are totally valid"
            },
            {
                id: 4,
                heading: "log rotation is set up properly or logs are not placed on the same server. If no monitoring is enabled eventually you'll wake up in the morning and your site will not work. Do we need this?"
            },
            {
                id: 5,
                heading: "check the file/folder permissions"
            },
            {
                id: 6,
                heading: "make sure that all analytics scripts are working properly"
            },
            {
                id: 7,
                heading: "replace all sandbox/test accounts for external system integration with the  production ones"
            },
        ]
    },
    {
        id: 11,
        title: "Go to production",
        anchor: "#release-to-production",
        items: [
            {
                id: 1,
                heading: "Wipe the M2 data, run the full migration, make a quick check",
            },
            {
                id: 2,
                heading: "Increase increment IDs for the dynamic data to the values not reachable by M1 while further steps are being performed. " +
                    "For example, if you have an order ID set to 1000 and you have 1 order/minute then a M2 increment ID is to be set to 10000 which gives about 6 days to handle other steps. " +
                    "And once M2 has been released to production the placed orders’ numbers will not intersect with the ones added by the delta import.",
            },
            {
                id: 3,
                heading: "open access to the front-end (remove basic authentication and IP restrictions)",
            },
            {
                id: 4,
                heading: "Switch all 3rd party to actual credentials and endpoints",
            },
            {
                id: 5,
                heading: "Switch DNS records (sometimes, not only A records need to be updated but also, for example MX, PTR, SPF, CNAME, etc.)",
            },
            {
                id: 6,
                heading: "Wait for the records to propagate",
            },
            {
                id: 7,
                heading: "Run the delta migration",
            },
            {
                id: 8,
                heading: "Process the full recheck",
            },
            {
                id: 9,
                heading: "Request recrawl from search engines",
            },
        ],
    },
    {
        id: 12,
        title: "After going to production",
        anchor: "#after-release-activities",
        items: [
            {
                id: 1,
                heading: "Closely follow security updates. You don't want personal data or CC information to be stolen using your store, right?"
            },
            {
                id: 2,
                heading: "Consider applying two-factor authentication both to the admin panel and to the access server(s) via SSH"
            },
            {
                id: 3,
                heading: "Reset TTL values to the original ones"
            },
            {
                id: 4,
                heading: "Consider using CDN. That is a separate \"hosting\" for static assets (e.g. images/JS/CSS)",
                list: [
                    {
                        id: 1,
                        label: "CDN will/might",
                        sublist: [
                            {
                                id: 1,
                                label: "reduce the load on your server (requests will hit CDN servers, not yours)"
                            },
                            {
                                id: 2,
                                label: "spread your assets over several locations around the world so your users will receive assets from the location which is closer to them in terms of the network infrastructure (say, it’s faster)"
                            },
                            {
                                id: 3,
                                label: "add the DDOS protection"
                            },
                        ]
                    },
                    {
                        id: 2,
                        label: "Some popular CDN services are:",
                        sublist: [
                            {
                                id: 1,
                                label: "Fastly (used by Magento Cloud)"
                            },
                            {
                                id: 2,
                                label: "Cloudflare"
                            },
                            {
                                id: 3,
                                label: "Akamai"
                            },
                        ]
                    }
                ]
            },
        ]
    }
];

export default upgradeData;


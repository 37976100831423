import React, { Component } from "react";
import { connect } from "react-redux";

import AboutUs from "../../components/AboutUs/AboutUs";
import Banner from "../../components/Banner/Banner";
import ContactUs from "../../components/ContactUs/ContactUs";
import HowToUpgrade from "../../components/HowToUpgrade/HowToUpgrade";
import ProsAndCons from "../../components/ProsAndCons/ProsAndCons";
import SoundsClear from "../../components/SoundsClear/SoundsClear";
import WhatWeDo from "../../components/WhatWeDo/WhatWeDo";


import { storeCleanUp } from "../../actions/upgradeActions"


class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state ={

        }
    }

    componentDidMount() {
        this.props.storeCleanUp();
        window.scrollTo(0,0)
    }


    render () {
        return (
          <div>
              <Banner/>
              <WhatWeDo/>
              <ProsAndCons/>
              <AboutUs/>
              <HowToUpgrade/>
              <SoundsClear/>
              <ContactUs/>
          </div>
        )
    }
}



export default connect(
    (state) => ({
        currentList: state.currentList
    }),
    {
        storeCleanUp
    }
)(HomePage);
import React, { Component } from "react";

import "./soundsclear.scss";

class SoundsClear extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render () {
        return (
          <section className="sounds-clear-section">
              <div className="container">
                  <h2 className="sounds-clear__title">Sounds clear?<span className="sounds-clear-rotate-item">or not?</span><span>Let's execute this!</span></h2>
              <p className="sounds-clear__description">
                  The above described info is what should be done at a relatively high level.
                  The very implementation often depends on the specifics of the project, it's size, data mandatory to transfer, and other factors.
                  Generally, you could follow common sense and process one step at a time that often results in something not quite expected.
                  Thus, we prefer to prepare a clear migration plan based on the codebase and the requirements investigation.
                  While processing, it will still have a few surprises but that starts to be manageable when there is a larger picture in place.
              </p>
              </div>
          </section>
        )
    }
}

export default SoundsClear
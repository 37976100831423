import React, { Component } from 'react';

import "./not-found.scss";

import contactUsMan from "assets/img/person_contact us.png";

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
 
  render () {
    return (
    <div className="not-found-page">
      <div className="container">
        <div className="not-found-text">
          <h3 className="not-found-title">404</h3>
          <p className="not-found-description">We are sorry but this is not the page you are looking for.</p>
        </div>
        <div>
          <img src={contactUsMan} alt="Contact Us" className="not-found__man"/>
        </div>
      </div>
    </div>
    )
  }


}

export default NotFound;
import React from "react";
import upgradeData from "../../containers/HowToUpgradePage/UpgradeData";
import SinglePageListItem from "./SinglePageListItem";

const singlePageList = props => {
    const {handleScrollIntoView} = props;

    return (
        <div className="list-section" >
            {upgradeData && upgradeData.map(({id, title, items, anchor}, i) =>
                <SinglePageListItem key={i} id={id} anchor={anchor} title={title} items={items} handleScrollIntoView={handleScrollIntoView} />
            )}
        </div>
    )
}

export default singlePageList;
import React, { Component } from "react";
import { render } from "react-dom";
import { HelmetProvider } from 'react-helmet-async';

import "./assets/style/main.scss";

import App from "./app";
import { Provider } from "react-redux";
import configureStore from "./helpers/configureStore";

const store = configureStore();
const helmetContext = {};

render( 
    <Provider store={store}>
        <HelmetProvider context={helmetContext}>
            <App />
        </HelmetProvider>
    </Provider>, document.getElementById("app")
)

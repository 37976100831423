import React, { Component } from "react";

import icon8 from "assets/icons/icon-08.svg";
import icon9 from "assets/icons/icon-09.svg";
import icon10 from "assets/icons/icon-10.svg";

import "./mightnotwork.scss";

const notWorkList = [
    {
        src: icon10,
        title: "Platform",
        caption: "You are planning to migrate from Magento to another platform. We do love Magento and will not let it down."
    },
    {
        src: icon8,
        title: "Command",
        caption: "You have a large enterprise installation which has taken you dozens of thousands of man-hours to develop and requires a really quick turnaround for migration. " +
            "Our Magento team is not that large (25 people) and we just might not have availability which is good enough to cover huge requests."
    },
    {
        src: icon9,
        title: "Time",
        caption: "You need things to get covered today or tomorrow at the latest. " +
            "We are trying to provide a quick turnaround but even a relatively basic store requires some analysis, a migration plan, data and content migration preparation. " +
            "Except quite simple cases it requires more than a week."
    },
];

class MightNotWork extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <section className="not-work-section">
                <div className="container">
                    <h2 className="not-work-title">
                        Why you might not want to work with us?
                    </h2>
                    <ul className="not-work-list">
                        {notWorkList && notWorkList.map(({src, title, caption}, i) =>
                            <li className="not-work-list-item" key={i}>
                                <span className="not-work-icon">
                                    <img src={src} alt={caption}/>
                                </span>
                                <div>
                                    <h3 className="list-item-title">{title}</h3>
                                    <p className="list-item-description">{caption}</p>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </section>
        );
    }
}

export default MightNotWork;
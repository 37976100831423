import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from 'react-router'

import "./thankyou.scss";

class ThankYou extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render () {
        if(!this.props.formComplete) { return <Redirect to = 'notFound'/> }
        return (
          <section className="thank-you-section">
              <div className="container">
                  <p className="thank-you__first">Thank you for your request.</p>
                  <p className="thank-you__second">We will get back to you very soon!</p>
              </div>
          </section>
        )
    }
}

export default connect(
    state => ({
        formComplete: state.formComplete
    }),
    {}
)(ThankYou);
import React, { Component } from "react";
import { connect } from "react-redux";

import { Link } from "react-router-dom"

import List from "../ListGrid/ListGrid";

import upgradeData from "containers/HowToUpgradePage/UpgradeData";

import { 
    displayUpdate,
    listToggler, 
    listIdHoverEnter,
    listIdHoverLeave,
 } from "../../actions/upgradeActions"

import "./howtoupgrade.scss";

const items = [
    { id: 1, caption: "Gather information" },
    { id: 2, caption: "Analyze your current M1" },
    { id: 3, caption: "Plan customizations" },
    { id: 4, caption: "Prepare server" },
    { id: 5, caption: "Prepare plan" },
    { id: 6, caption: "Staging deployment" },
    { id: 7, caption: "Transfer data" },
    { id: 8, caption: "Test staging server" },
    { id: 9, caption: "Prepare production" },
    { id: 10, caption: "Test production" },
    { id: 11, caption: "Release to production" },
    { id: 12, caption: "After release activities" },
];

class HowToUpgrade extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
        }
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleScroll = () => {
        if (!this.state.upgradeDisplay) {
            this.refs.scrollTo.scrollIntoView({
                behavior: "smooth",
                block: "start"
            })
        }
    };

    displayUpgrade = () => {
        this.props.listToggler(1);
        if (this.props.currentList === null) {
            this.handleScroll()
        }
    };

    handleMapClick = (id) => {
        const { width } = this.state;
        const isMobile = width <= 600;

        this.props.displayUpdate(id);

        if (isMobile) {
            setTimeout(function() {
                document.getElementById(id).childNodes[1].style.display = "block";
                document.getElementById(id).childNodes[1].scrollIntoView({
                    behavior: "smooth",
                    block: "nearest"
                }) 
            }, 300)
        } else {
        this.handleScroll()
        }
    };

    render () {
        return (
            <section className="how-to-upgrade">
                <div className="container">
                <h2 className="how-to-upgrade__title">
                    So how to upgrade Magento?
                </h2>
                <ul className="how-to-upgrade__list">
                    {items.map(({id, caption}, i) =>
                        <li 
                            className={"how-to-upgrade__list-item" + (this.props.activeListId === id ? " active" : "")} 
                            key={i} 
                            data-id={id} 
                            onClick={() => this.handleMapClick(id)}
                            onMouseEnter={() => this.props.listIdHoverEnter(id)}
                            onMouseLeave={() => this.props.listIdHoverLeave(id)}
                            >
                            <span>{caption}</span>
                        </li>
                    )}
                </ul>
                <div className="how-to-upgrade__buttons">
                    <button onClick={() => this.displayUpgrade(1)}></button>
                    <Link to={"/how-to-upgrade"} alt="how-to-upgrade" className="button-redirect"/>
                </div>
                    <div ref="scrollTo" className="scrollTo"></div>
                    { this.props.currentList && <List /> }
                </div>
            </section>
        )
    }
}

export default connect(
    (state) => ({
        currentList: state.currentList,
        activeListId: state.activeListId
    }),
    {
        listToggler,
        displayUpdate,
        listIdHoverEnter,
        listIdHoverLeave,
    }
)(HowToUpgrade);
import { handleActions } from 'redux-actions';

import upgradeData from "../containers/HowToUpgradePage/UpgradeData";

const initialState = {
    currentList: null,
    activeListId: 1,
    formComplete: false
}

export default handleActions({
    STORE_UPDATE: (state, action) => ({
        ...state,
        currentList: upgradeData.find(x => x.id === action.payload.id)
    }),
    STORE_CLEAN_UP: (state, action) => ({
        ...state,
        currentList: null
    }),
    DISPLAY_UPDATE: (state, action) => ({
        ...state,
        currentList: upgradeData.find(x => x.id === action.payload.id)    
    }),
    LIST_ID_HOVER_ENTER: (state, action) => ({
        ...state,
        activeListId: action.payload.id
    }),
    LIST_ID_HOVER_LEAVE: (state, action) => ({
        ...state,
        activeListId: null
    }),
    LIST_CLICK_UPDATE: (state, action) => ({
        ...state,
        currentList: upgradeData.find(x => x.id === action.payload.id)
    }),
    LIST_TOGGLE: (state, action) => ({
        ...state,
        currentList: !state.currentList ?  upgradeData.find(x => x.id === action.payload.id) : null
    }),
    BUTTON_DIRECTION_CLICK: (state, action) => ({
        ...state,
        currentList: upgradeData.find(x => x.id === action.payload.id)
    }),
    FORM_COMPLETE: (state, action) => ({
        ...state,
        formComplete: action.payload.formComplete
    }),
}, initialState);

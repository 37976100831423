import icon1 from "assets/icons/icon-01.svg";
import icon2 from "assets/icons/icon-02.svg";
import icon3 from "assets/icons/icon-03.svg";
import icon4 from "assets/icons/icon-04.svg";
import icon5 from "assets/icons/icon-05.svg";
import icon6 from "assets/icons/icon-06.svg";
import icon7 from "assets/icons/icon-07.svg";



const aboutUsData = [
    {
        src: icon1,
        caption: "No multi-tier hierarchy. We are not that large (it’s 25 people in our Magento team). So, you'll have proper attention from both the management and the developers."
    },
    {
        src: icon2,
        caption: "MageWorkshop team specializes in Magento. We started when Magento 1.3 was just on the horizon and we still keep growing just as Magento does."
    },
    {
        src: icon3,
        caption: "No freelance/remote contractors. We are not trying to scale out sacrificing quality and juggling with resources."
    },
    {
        src: icon4,
        caption: "Good communication flow. You'll have:",
        list: [
            {
                caption: "daily reports on the development progress"
            },
            {
                caption: "access to the issue tracker to observe the progress in real time"
            },
            {
                caption: "access to the team's messaging to discuss urgent things or those ones which require extensive communication"
            },
            {
                caption: "ability to schedule a conference to go over the project state and the plan interactively"
            },
            {
                caption: "escalation of any unpredicted issues or obstacles (which might affect the plan development schedule) beforehand"
            },
            {
                caption: "exact understanding of what stage the current development is at and when the current (sub)stage must be accomplished"
            },
        ]
    },
    {
        src: icon5,
        caption: "We have an expertise in migration which includes:",
        list: [
            {
                caption: "knowledge of the most common issues"
            },
            {
                caption: "practice operating with the migration tools"
            },
            {
                caption: "ability to run upgrades with minimal (or no) downtime"
            },
            {
                caption: "own toolset to be used during the migration"
            },
            {
                caption: "knowledge of what should go and where during the migration"
            },
            {
                caption: "ability to fully handle the migration process (including servers’ setup, DNS handling, SSL installation, etc.)"
            },
        ]
    },
    {
        src: icon6,
        caption: "We are located in central Ukraine, homeland of the Magento development."
    },
    {
        src: icon7,
        caption: "We have both the strategic and the tactical plan."
    }
];

export default aboutUsData;
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom"

import { displayUpdate } from "../../actions/upgradeActions"
import { listClickUpdate } from "../../actions/upgradeActions"
import { handleButtonDirectionClick } from "../../actions/upgradeActions"

import upgradeData from "containers/HowToUpgradePage/UpgradeData";

import "./list.scss";
import FullPageListItem from "./FullPageListItem";
import SinglePageList from "./SinglePageList";

const select = [
    {id: 1, title: "Gather Information", link: ""},
    {id: 2, title: "Analyze your current M1", link: "/analyze-current-m1"},
    {id: 3, title: "Plan customization", link: "/plan-customization" },
    {id: 4, title: "Prepare server", link: "/prepare-server"},
    {id: 5, title: "Prepare plan", link: "/prepare-plan"},
    {id: 6, title: "Staging deployment", link: "/staging-deployment"},
    {id: 7, title: "Transfer data", link: "/transfer-data"},
    {id: 8, title: "Test staging server", link: "/test-staging-server"},
    {id: 9, title: "Prepare production", link: "/prepare-production"},
    {id: 10, title: "Test production", link: "/test-production"},
    {id: 11, title: "Release to production", link: "/release-to-production"},
    {id: 12, title: "After release activities", link: "/after-release-activities"},
];

class List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            animate: false,
            currentListId: 1
        };
        this.selectHeader = React.createRef();
        this.fullpageNav = React.createRef();
        this.howUpgradeSection = React.createRef();
    }

    componentDidMount () {
        const { pathname } = location;
        const path = this.props.match && this.props.match.path;
        const id = path === undefined ? upgradeData[0] : path.slice(15, path.length);
        const selectedId = select.find(x => x.link === id);
        const preparedListValue = upgradeData.find(z => z.id === (selectedId && selectedId.id)) === undefined ? upgradeData[0] : upgradeData.find(z => z.id === (selectedId && selectedId.id));
        
        if (pathname === "/how-to-upgrade" ) {
            window.scrollTo(0,0)
            this.updateListSelectPosition();
        }

        if (pathname !== "/") {
            this.props.displayUpdate(preparedListValue.id)
        }

        this.isScrollBusy = false;
    }

    componentWillMount() {
        window.addEventListener('scroll', this.handleScrollChange);
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScrollChange);
        window.removeEventListener('resize', this.handleWindowSizeChange);
        clearTimeout(this.scrollTimeout);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth});
        this.updateListSelectPosition();
    };

    handleScrollChange = () => {
        this.updateListSelectPosition();
    }

    updateListSelectPosition = () => {
        if (window.innerWidth >= 1024 && this.fullpageNav.current && this.howUpgradeSection.current) {
            const containerRect = this.howUpgradeSection.current.getBoundingClientRect();
            const containerPaddingTop = Number.parseFloat(window.getComputedStyle(this.howUpgradeSection.current).paddingTop);
            const navRectHeight = this.fullpageNav.current.getBoundingClientRect().height;
            const bottomOffset = containerRect.bottom;
            const topOffset = containerRect.top;
            const bottomPadding = 50;

            if (topOffset > 0) {
                this.fullpageNav.current.style.top = `${topOffset + containerPaddingTop}px`;
            } else {
                if (bottomOffset > navRectHeight + containerPaddingTop + bottomPadding) {
                    this.fullpageNav.current.style.top = '';
                }
                else {
                    this.fullpageNav.current.style.top = `${bottomOffset - navRectHeight - bottomPadding}px`;
                }
            }
        }
    }

    handleScroll = () => {
        if (location.pathname === "/") {
            /** fix for FF */
            setTimeout(() => {
                document.querySelector(".scrollTo").scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                })
            }, 10)

        } else {
            document.querySelector(".list-title").scrollIntoView({
                behavior: "smooth",
                block: "end"
            })  
        }
    }

    onListChange = (id, entity) => {
        const { width } = this.state;
        const isMobile = width <= 1024;
        const clickedList = entity.currentTarget.querySelector(".list-section");;
        this.props.listClickUpdate(id);


        if (isMobile) {
            if (clickedList.style.display === "block") {
                clickedList.style.display = "none";
                entity.currentTarget.style.color = "black";
                entity.currentTarget.querySelector(".list-select-title").style.backgroundColor = "inherit";
                document.querySelector(".how-to-upgrade-title").scrollIntoView();
                entity.currentTarget.querySelector(".how-to-upgrade-arrow").style.transform = "rotate(270deg)"
            } else {
                clickedList.style.display = "block";
                entity.currentTarget.style.color = "#f37221";
                entity.currentTarget.querySelector(".list-select-title").style.backgroundColor = "#f6f6f6";
                entity.currentTarget.querySelector(".how-to-upgrade-arrow").style.transform = "rotate(90deg)"
            } 
        } else {
            this.handleScroll();
        }
    };

    handlePrevious = () => {
        const { 
            currentList,
            handleButtonDirectionClick
        } = this.props;
        handleButtonDirectionClick(currentList.id - 1);

        this.handleScroll();
    };

    handleNext = () => {
        const { 
            currentList,
            handleButtonDirectionClick
        } = this.props;

        handleButtonDirectionClick(currentList.id + 1);
        this.handleScroll();
    };


    handleListRender = ( listValue ) => {
        const { width } = this.state;
        const isMobile = width <= 600;
        const id = this.props.match && this.props.match.params.id;
        const selectedId = select.find(x => x.link === "/" + id);
        const preparedListValue = listValue || listValue !== null ? listValue : upgradeData.find(z => z.id === (selectedId && selectedId.id));
        

        if (!preparedListValue) {
            return <div></div>
        }
        return ( 
            <div className="list-section" >
                {isMobile ? "" : <h3 data-id={preparedListValue.id} className="list-title">{preparedListValue.title}</h3>}
                <ul className="list-level-one">
                    {preparedListValue && preparedListValue.items.map(({id, heading, list}, i) =>
                        <li key={i} className="list-level-one-item" data-id={id}>{heading}
                            {list ? <ul className="list-level-two">
                                {list && list.map(({label, sublist}, i) =>
                                    <li key={i} className="list-level-two-item">
                                    {label}
                                    {sublist ?  <ul className="list-level-three">
                                            {sublist && sublist.map(({label, sublist}, i) =>
                                                <li key={i} className="list-level-three-item">{label}
                                                    {sublist ? <ul className="list-level-four">
                                                        {sublist && sublist.map(({label}, i) =>
                                                            <li key={i} className="list-level-four-item">{label}</li>
                                                        )}
                                                    </ul> : ""}
                                                </li>
                                            )}
                                        </ul> : ""}
                                    </li>
                                )}
                            </ul> : ""}
                        </li>
                    )}
                </ul>
            </div>
        )
    }

    updateSelectedStep = (anchor, id) => {
        const { history, location } = this.props;

        this.setState({
            currentListId: id
        })

        if (location.hash) {
            history.push(anchor)
            return;
        }

        history.push("how-to-upgrade" + anchor)
    }

    handleFullpageListClick = (anchor, id) => {
        this.updateSelectedStep(anchor, id);
        this.isScrollBusy = true;

        document.getElementById(anchor).scrollIntoView({
            behavior: "smooth",
            block: "start"
        });

        /**
         * it's not necessary to know expect time when scroll is over to setup an scroll event listener
         * just need an appx delay
         */
        this.scrollTimeout = setTimeout(() => {
            this.isScrollBusy = false;
        }, 700)
    }

    handleScrollIntoView = (anchor, id) => {
        if (!this.isScrollBusy) {
            this.updateSelectedStep(anchor, id);
        }
    }

    render () {
        const { width } = this.state;
        const isMobile = width <= 600;
        const pathname = this.props.location ? this.props.location.pathname.slice(0,15) : null ;
        const select = [
            {id: 1, title: "Gather Information", link: "", anchor: "#gather-information"},
            {id: 2, title: "Analyze your current M1", link: "/analyze-current-m1", anchor: "#analyze-current-m1"},
            {id: 3, title: "Plan customization", link: "/plan-customization", anchor: "#plan-customization" },
            {id: 4, title: "Prepare server", link: "/prepare-server", anchor: "#prepare-server"},
            {id: 5, title: "Prepare plan", link: "/prepare-plan", anchor: "#prepare-plan"},
            {id: 6, title: "Staging deployment", link: "/staging-deployment", anchor: "#staging-deployment"},
            {id: 7, title: "Transfer data", link: "/transfer-data", anchor: "#transfer-data"},
            {id: 8, title: "Test staging server", link: "/test-staging-server", anchor: "#test-staging-server"},
            {id: 9, title: "Prepare production", link: "/prepare-production", anchor: "#prepare-production"},
            {id: 10, title: "Test production", link: "/test-production", anchor: "#test-production"},
            {id: 11, title: "Release to production", link: "/release-to-production", anchor: "#release-to-production"},
            {id: 12, title: "After release activities", link: "/after-release-activities", anchor: "#after-release-activities"},
        ];

        if (isMobile) {
            return (
                <div className="how-to-upgrade-section" >
                    <div className="container"> 
                    <h3 className="how-to-upgrade-title">How to Upgrade</h3>
                        <div className="how-to-upgrade-article" >
                            <ul>
                                {select && select.map(({id, title}, i) =>
                                    <li 
                                        key={i} 
                                        id={id} 
                                        className="list-select-item"
                                        onClick={(entity) => this.onListChange(id, entity)} 
                                        ref={this.selectHeader}
                                        >
                                            <p className="list-select-title">{title} <span className="how-to-upgrade-arrow"></span></p>
                                            {this.handleListRender(upgradeData[i])}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            )
        }
        else if (pathname !== "/how-to-upgrade") {
            return (
                <div className="how-to-upgrade-section" >
                    <div className="container" >
                        <div className="how-to-upgrade-article" >
                            {this.handleListRender(this.props.currentList)}
                            <div className="list-select" >
                                <h3>How to Upgrade</h3>
                                <ul>
                                    {select && select.map(({id, title, link}, i) =>
                                    <li 
                                        className={(this.props.currentList && this.props.currentList.id === id ? "selected" : "")}
                                        key={i} 
                                        data-id={id} 
                                        onClick={(entity) => this.onListChange(id, entity)} 
                                        ref={this.selectHeader}
                                        >
                                            {pathname === "/how-to-upgrade" ?
                                                <Link to={pathname + link}
                                                    className="list-link"
                                                    alt={title}  
                                                >
                                                    {title}
                                                </Link>
                                                : <span className="list-link">
                                                    {title}
                                                </span> 
                                            }
                                    </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="upgrade-buttons">
                            {this.props.currentList !== null && this.props.currentList.id > 1 ? <button
                                className="btn btn-steps"
                                onClick={() => this.handlePrevious()}
                                disabled={this.state.isPreviousDisabled}
                            >
                            Previous Step
                            </button>: "" }
                            {this.props.currentList !== null && this.props.currentList.id < upgradeData.length ? <button
                                className="btn btn-steps btn-next"
                                onClick={() => this.handleNext()}
                                disabled={this.state.isNextDisabled}
                            >
                            Next Step
                            </button>: "" }
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="how-to-upgrade-section" >
                    <div className="container" >
                        <div ref={this.howUpgradeSection} className="how-to-upgrade-article" style={{paddingBottom: "70px"}} >
                            <SinglePageList handleScrollIntoView={this.handleScrollIntoView} />
                            <div ref={this.fullpageNav} className="list-select">
                                <h3>How to Upgrade</h3>
                                <ul>
                                    {select && select.map(({id, title, anchor}, i) =>
                                        <FullPageListItem
                                            key={i}
                                            className={(this.state.currentListId && this.state.currentListId === id ? "selected" : "")}
                                            id={id}
                                            handleFullpageListClick={() => this.handleFullpageListClick(anchor, id)}
                                            title={title}
                                        />
                                    )}
                                </ul>
                            </div>
                        </div>    
                    </div>
                </div>
            )
        }
    }
}


export default connect(
    (state) => ({
        currentList: state.currentList
    }),
    {
        displayUpdate,
        listClickUpdate,
        handleButtonDirectionClick
    }
)(List);
import React, { Component } from 'react';

import "./whatwedo.scss";

import house from "assets/img/what we do .svg";
import mountain from "assets/img/why.svg";
import tricky from "assets/img/track-01.svg";



class WhatWeDo extends Component {
    constructor(props) {
        super(props);
        this.state = { };
    }

    render() {
        const items = [

            {
                id: 1,
                altText: 'Slide 2',
                caption: 'MageWorkshop team handles Magento store migration between major versions (M1 to M2). Properly.',
                heading: "What do we do?",
                src: house,
            },
            {
                id: 2,
                altText: '',
                caption: 'Not at all... * Well, if you have a vanilla Magento installation with no significant customization, no extensions installed, no theme applied. ' +
                    'So, unless you are running such store in the time free from herding a spherical cow upgrading Magento will not be that smooth.',
                heading: "Is it that tricky?",
                src: tricky,
            },
            {
                id: 3,
                altText: '',
                caption: 'Because we can. Having several migrations behind we’ve gathered some experience. And now when all legs are shot we are ready for the march.',
                heading: "And why?",
                src: mountain,
            }
        ];


        return(
            <section className="what-we-do">
                <div className="container">
                    <ul className="what-we-do__list">
                        {items.map(({caption, heading, src}, i) =>
                            <li className="what-we-do__list-item" key={i}>
                                <h3 className="what-we-do__list-item__title">{heading}</h3>
                                <img src={src} alt={heading} />
                                <p className="what-we-do__list-item__description">{caption}</p>
                            </li>
                        )}
                    </ul>
                </div>
          </section>
        )
    }
}

export default WhatWeDo;
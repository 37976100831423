import { createAction } from "redux-actions";

export const STORE_UPDATE = "STORE_UPDATE";
export const DISPLAY_UPDATE = "DISPLAY_UPDATE";
export const LIST_CLICK_UPDATE = "LIST_CLICK_UPDATE";
export const LIST_TOGGLE = "LIST_TOGGLE";
export const BUTTON_DIRECTION_CLICK = "BUTTON_DIRECTION_CLICK";
export const STORE_CLEAN_UP = "STORE_CLEAN_UP";
export const LIST_ID_HOVER_ENTER = "LIST_ID_HOVER_ENTER";
export const LIST_ID_HOVER_LEAVE = "LIST_ID_HOVER_LEAVE";
export const FORM_COMPLETE = "FORM_COMPLETE";

export const storeUpdate = createAction(STORE_UPDATE, ( id = 1 ) => ({ id }));
export const storeCleanUp = createAction(STORE_CLEAN_UP);

export const displayUpdate = createAction(DISPLAY_UPDATE, ( id = 1 ) => ({ id }));

export const listIdHoverEnter = createAction(LIST_ID_HOVER_ENTER, ( id ) => ({ id }) );
export const listIdHoverLeave = createAction(LIST_ID_HOVER_LEAVE);

export const listClickUpdate = createAction(LIST_CLICK_UPDATE, ( id = 1 ) => ({ id }));
export const listToggler = createAction(LIST_TOGGLE, ( id = 1 ) => ({ id }));

export const handleButtonDirectionClick = createAction(BUTTON_DIRECTION_CLICK, ( id ) => ({ id }) );

export const isFormCompeted = createAction(
    FORM_COMPLETE,
    (formComplete = false) => ({
        formComplete
    })
);

import React, {useEffect} from "react";
import {useInView} from "react-intersection-observer";

const singlePageListItem = props => {
    const {id, anchor, title, items, handleScrollIntoView} = props;
    const {ref, inView} = useInView({
        threshold: 0.2,
    });

    useEffect(() => {
        if (inView) {
            handleScrollIntoView(anchor, id)
        }
    }, [inView])

    return (
        <ul className="list-level">
            <li className="title-fullpage" data-id={id} id={anchor} >{title}</li>
            <ul className="list-level-one" ref={ref}>
                {items && items.map(({id, heading, list}, i) =>
                    <li key={i} className="list-level-one-item-fullpage" data-id={id}>{heading}
                        {list ? <ul className="list-level-two">
                            {list && list.map(({label, sublist}, i) =>
                                <li key={i} className="list-level-two-item">
                                    {label}
                                    {sublist ?  <ul className="list-level-three">
                                        {sublist && sublist.map(({label, sublist}, i) =>
                                            <li key={i} className="list-level-three-item">{label}
                                                {sublist ? <ul className="list-level-four">
                                                    {sublist && sublist.map(({label}, i) =>
                                                        <li key={i} className="list-level-four-item">{label}</li>
                                                    )}
                                                </ul> : ""}
                                            </li>
                                        )}
                                    </ul> : ""}
                                </li>
                            )}
                        </ul> : ""}
                    </li>
                )}
            </ul>
        </ul>
    )
}

export default singlePageListItem;
import React, { Component } from "react";
import { Link } from "react-router-dom"

import "./aboutus.scss"
import AboutUsPage from "../../containers/AboutUsPage/AboutUsPage"

class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            aboutDisplay: false
        }
    }

    
    handleScroll = () => {
        if (!this.state.aboutDisplay) {
            document.querySelector(".about-us__buttons").scrollIntoView({
                behavior: "smooth",
                block: "start"
            })
        }
    };
    
    displayAboutUs = () => {
        this.setState({
            aboutDisplay: !this.state.aboutDisplay
        })

        this.handleScroll();
    };

    render() {
        return (
            <section >
            <div className="about-us-section">
                <div className="container">
                    <h2 className="about-us__title">
                        About us
                    </h2>
                    <p className="about-us__description">
                        It is important to select a proper partner that can lead the process and guarantee a high level of
                        execution quality. Let's be honest - there is no company that can handle everything. Click the button
                        below to read some information on why you might or might not want to work specifically with us.
                    </p>
                    <div className="about-us__buttons">
                        <button onClick={this.displayAboutUs}></button>
                        <Link to={"/about-us"} alt="about-us" className="button-redirect"/>
                    </div>
                </div>
            </div>
            { this.state.aboutDisplay && <AboutUsPage {...this.state}/> }
            </section>
        )
    }
}

export default AboutUs;
import React, { Component } from "react";

import "./banner.scss";

class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    scrollToContact = () => {
        document.querySelector(".contact-us").scrollIntoView({
            behavior: "smooth"
        })
    };

    render() {
        return(
          <section className="welcome-section">
              <div className="container">
                  <h1 className="welcome-section__title">Get help with Magento 1 to Magento 2 migration</h1>
                  <button className="btn btn-contact" onClick={this.scrollToContact}>Request migration</button>
              </div>
          </section>
        )
    }
}

export default Banner;


import React, { Component } from "react";
import axios from 'axios';

import Loader from 'react-loader-spinner';

import ReCAPTCHA from "react-google-recaptcha";

import "./contactus.scss";
import contactUsMan from "assets/img/person_contact us.png";

import { Redirect } from 'react-router'
import {connect} from "react-redux";
import {isFormCompeted} from "../../actions/upgradeActions";

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            message: "",
            data: null,
            loaded: false,
            loading: false,
            errorName: "",
            errorMessage: ""
        }
        this.recaptchaRef = React.createRef();
    }

    state = { redirect: false }

    componentDidMount() {

    }

    executeCaptcha = (e) => {
        e.preventDefault();
        this.recaptchaRef.current.execute()
    }

    handleSubmit(){
        this.setState({
            loading: true,
            loaded: false
        })
        const name = document.getElementById('contact-us-name').value;
        const email = document.getElementById('contact-us-email').value;
        const message = document.getElementById('contact-us-message').value;
        if (!name.trim()) {
            this.setState({
                errorName: "Field 'name' is not valid",
                errorMessage: "",
                loading: false
            })
        } else if (!message.trim()) {
            this.setState({
                errorMessage: "Field 'message' is not valid",
                errorName: "",
                loading: false
            })
        } else {
            axios({
                method: "POST",
                url:"/api/send",
                data: {
                    "name": name,
                    "email": email,
                    "message": message
                }
            }).then((response)=> {
                if (response.data.msg === 'success') {
                    this.props.isFormCompeted(true)
                    this.setState({
                        loading: false,
                        loaded: response.data.msg,
                        redirect: true,
                    })
                } else if (response.data.msg === 'fail'){
                    this.setState({
                        loading: false,
                        loaded: response.data.msg
                    })
                }
            })
        }


    }

    render() {
        const { loaded, loading, redirect } = this.state;

        if (redirect) { return <Redirect to='/thank-you' /> }

        return (
            <section className="contact-us" style={(location.pathname !== "/" && location.pathname !== "/about-us") ? {background: "#eff2f7"} : {} }>
                <div className="container">
                    <h2 className="contact-us__title">
                        Request Magento 1 to Magento 2 Migration
                    </h2>
                    <p
                    className="contact-us__description"
                    style={loaded === "success" ? {color: "green"}
                    : loaded === "fail" ? {color: "red"} : {}}
                    >
                    {loaded === "success" ? "Thank you for submitting your message!"
                    : loaded === "fail" ? "Sending failed, please, try again"
                    : [
                        <span key="other" className="contact-us_description-subtext">We are here to help you with smooth and safe Magento migration.<br/> Provide us with some details and we will get back to you soon.</span>
                    ]}</p>

                    <form method="POST" onSubmit={this.executeCaptcha.bind(this)} className="contact-us__form" id="contact-form">
                    <small className="errorMessage">{this.state.errorName}</small>
                    <small className="errorMessage">{this.state.errorMessage}</small>
                        <label htmlFor="name" />
                        <input
                            name="name"
                            id="contact-us-name"
                            className="contact-us__input"
                            placeholder="Your name"
                            required
                        />
                        <label htmlFor="email" />
                        <input
                            name="email"
                            id="contact-us-email"
                            className="contact-us__input"
                            placeholder="Your email"
                            size="128"
                            maxLength="128"
                            required
                            pattern="^([\w\-]+)@([\w\-]+)\.([a-zA-Z]{2,5})$"
                        />
                        <label htmlFor="message" />
                        <textarea
                            name="message"
                            id="contact-us-message"
                            cols="80"
                            rows="13"
                            className="contact-us__textarea"
                            placeholder="Enter a message to contact us!"
                            required
                        />
                            <button
                                type="submit"
                                className="btn btn-form"
                            >
                                Submit request
                            </button>
                                {!loaded && loading ?
                                    <div className="loader">
                                        <Loader
                                        type="TailSpin"
                                        color="#f37221"
                                        height="80"
                                        width="80"
                                        />
                                    </div>:""}
                                    <ReCAPTCHA
                                        style={{display: "block"}}
                                        ref={this.recaptchaRef}
                                        size="invisible"
                                        sitekey="6LerJwoaAAAAAHKjVmR6abBLaTvL7pKgdh9hQzH8"
                                        onChange={this.handleSubmit.bind(this)}
                                    />
                    </form>
                    <img src={contactUsMan} alt="Contact Us" className="contact-us__man"/>
                </div>
            </section>
        )
    }
}


export default connect(
    null,
    {
        isFormCompeted
    }
)(ContactUs);

import React, { Component } from "react";
import { connect } from "react-redux";

import List from "../../components/ListGrid/ListGrid";
import ContactUs from "../../components/ContactUs/ContactUs";
import { storeUpdate } from "../../actions/upgradeActions"


class UpgradePage extends Component {
    constructor(props) {
        super(props);
    }
    
    componentDidMount() {
        (location.pathname === "/how-to-upgrade") ? this.props.storeUpdate(1) : ""
    }

    render() {
        return (
          <div>
              <List {...this.props}/>
              <ContactUs />
          </div>
        );
    }

}

export default connect(
    (state) => ({
        currentList: state.currentList
    }),
    {
        storeUpdate
    }
)(UpgradePage);
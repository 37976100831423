const listData = [
    {
        classname: "pros_list-item",
        caption: "You just don't want to bother. Initially, Magento announced a 3 year end of life period for M1 version. " +
            "That was after the <b>M2</b> release in 2015. That does not mean Magento 1 will be abandoned (no doubt it will be functional after that time). " +
            "However, neither active development (that is already actual), nor security patches will be available. " +
            "This year, Magento guys have provided an official but a bit vague update in <a class=pros-cons-link target=_blank href=https://magento.com/blog/magento-news/supporting-magento-1-through-june-2020>Magento News</a> which might or might not mean something."
    },
    {
        classname: "cons_list-item",
        caption: "Customer experience is better. Key processes which used to be enhanced by 3rd party extensions are now polished out of the box, will be improved with new releases " +
            "and will be a part of the core instead of staying apart and bonusing you with quality \"surprises\" and compatibility mess with every upgrade."
    },
    {
        classname: "pros_list-item",
        caption: "M1 has a great extension ecosystem. It’s been standing its ground for years. " +
            "There is a chance that a significant part of extensions you are using have not been implemented under M2 yet. " +
            "That's often not the case but happens. If the functionality provided by extensions which are missing in <b>M2</b> is mandatory, " +
            "then you'll either end up with custom development or postpone migration with a hope that, eventually, some M1 extension vendor will rewrite it."
    },
    {
        classname: "cons_list-item",
        caption: "According to the recent updates (<a class=pros-cons-link target=_blank href=https://magento.com/blog/magento-news/supporting-magento-1-through-june-2020>Magento blog</a>), Magento is providing " +
        "software security patches through <a class=pros-cons-link target=_blank href=https://magento.com/blog/magento-news/supporting-magento-1-through-june-2020>June 2020</a>. " + 
        "That's good news unless you mention the thing that <a class=pros-cons-link target=_blank href=http://php.net/supported-versions.php/>РНР 5.6</a> won't be supported starting from January, 2019"
    },
    {
        classname: "pros_list-item",
        caption: "The store development route is covered with sweat, tears and blood. " +
            "You have invested dozens of thousands of effective man-hours to set things up and you can't survive another time. " +
            "It will take less time to reimplement existing logic, it will be cleaner when redone from scratch but it will still take resources."
    },
    {
        classname: "cons_list-item",
        caption: "<b>M2 is already stable</b> and a lot of merchants are successfully running their M2 stores."
    },
    {
        classname: "pros_list-item",
        caption: "You are considering a switch to another eCommerce platform. Magento is good but it's not a Jack of all trades. " +
            "If you don't use most of the features Magento offers and don't plan to customize it then some SaaS system could be considered as well as " +
            "another self-hosted platform which is easy in development but still provides some eCommerce capabilities. Maybe you have specific requirements which " +
            "are not covered well enough in Magento but are good in another system? It’s reasonable to make an educated decision here. " +
            "Contact us and we'll share our opinion for free (promise not to \"sell\" you Magento)."
    },
    {
        classname: "cons_list-item",
        caption: "Magento guys have some expertise in eCommerce. They’ve taken into account the flaws of the existing solutions " +
            "(including M1 they’ve been developing for years) and introduced a <b>new system</b>. Worth checking, right?"
    },
    {
        classname: "pros_list-item",
        caption: "If to consider an average price M1 development is cheaper. There is still a significant amount of developers who have not bothered or had a chance to learn <b>M2.</b> " +
            "Also 90% of <b>M2</b> developers are experienced with M1. Thus, the market of M1 developers is currently larger comparing with the one of <b>M2</b>. Additionally, " +
            "not all developers have passed <b>M2</b> learning curve (I would not allow such guys to get close to my store, though)."
    },
    {
        classname: "cons_list-item",
        caption: "<b>M2</b> is being actively developed. It will follow global eCommerce trends while M1 stands at the same point."
    },
    {
        classname: "cons_list-item",
        caption: "Magento has introduced  an extension quality program which provides <b>some protection</b> from the totally screwed up code. " +
            "Things are not perfect here but still much better than nothing."
    },
    {
        classname: "cons_list-item",
        caption: "<b>M2 has good modern architecture.</b> While it has a more significant learning curve comparing with M1 or " +
            "other solutions from the same market niche it gives a very good ground (or a high-level framework if one prefers) for development."
    },
    {
        classname: "cons_list-item",
        caption: "M2 is good for complex solutions. Better architecture allows to <b>gracefully handle</b> more challenging applications and decrease the probability of falling to the codebase mess when the development ends up with tiny features born in huge continuous pain."
    },
    {
        classname: "cons_list-item",
        caption: "New extensions and themes are developed only for M2"
    },
];

export default listData;
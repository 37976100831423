import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import { createBrowserHistory } from 'history';
import Header from "./components/Header/Header";
import HomePage from "./containers/HomePage/HomePage";
import AboutUsPage from "./containers/AboutUsPage/AboutUsPage";
import Footer from "./components/Footer/Footer";
import UpgradePage from "./containers/HowToUpgradePage/HowToUpgradePage";
import NotFound from './containers/NotFound/NotFound';
import ThankYou from './components/ThankYou/ThankYou'
import Head from "./components/Head/Head";


const customHistory = createBrowserHistory();

// const routes = [
//     "/how-to-upgrade",
//     "/how-to-upgrade/analyze-current-m1",
//     "/how-to-upgrade/plan-customization",
//     "/how-to-upgrade/prepare-server",
//     "/how-to-upgrade/prepare-plan",
//     "/how-to-upgrade/staging-deployment",
//     "/how-to-upgrade/transfer-data",
//     "/how-to-upgrade/test-staging-server",
//     "/how-to-upgrade/prepare-production",
//     "/how-to-upgrade/test-production",
//     "/how-to-upgrade/release-to-production",
//     "/how-to-upgrade/after-release-activities"
// ]

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    
    render() {

        return (
            <Router history={customHistory}>
                <div>
                    <Head />
                    <Header/>
                    <Switch>
                    <Route exact path="/" component={HomePage}/>
                    {/* {routes.map(route =>
                    <Route exact path={route} component={UpgradePage} key={route}/>
                    )} */}
                    <Route exact path="/how-to-upgrade" component={UpgradePage}/>
                    <Route exact path="/about-us" component={AboutUsPage} />
                    <Route exact path="/thank-you" component={ThankYou} />
                    <Route path="*" component={NotFound} status={404}/>
                    </Switch>
                    <Footer/>
                </div>
            </Router>
        );
    }
}

export default App;
window.Tawk_API = window.Tawk_API || {};
window.Tawk_LoadStart = new Date();

(function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/5be99a990e6b3311cb78e973/default';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
})();


Tawk_API.onChatMaximized = () => {
    var url = 'https://hooks.slack.com/services/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';
    var text = '<@maksymk> Chat is opened';
    var xhr = new XMLHttpRequest();
    var body = 'payload=' + JSON.stringify({"text": text, "link_names": true });
    xhr.open('POST', url, true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.send(body);
};



import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import storeReducer from "../reducers/storeReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default function configureStore(InitialState) {
    return createStore(
        storeReducer,
        InitialState,
        composeEnhancers(
            applyMiddleware(
                thunkMiddleware
            )
        )
    )
}
import React, { Component } from "react";

import envelope from "assets/icons/envelope.svg";
import telephone from "assets/icons/telephone.svg";
import "./footer.scss";
import "./tawk-chat.js";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false
        }
    }

    scrollToContact = () => {
        document.querySelector(".contact-us").scrollIntoView({
            behavior: "smooth"
        })
    };

    toggleModal = () => {
        this.setState({
            isModalOpen: !this.state.isModalOpen
        })
    };

    render ()  {
        const isHome = location.pathname === "/";
        const footer = [
            { href: "/", caption: "Home" },
            { href: "/about-us", caption: "About Us" },
            { href: "/how-to-upgrade", caption: "How To Upgrade" },
            { href: "#contact-us", caption: "Contact Us", click: this.scrollToContact},
        ];

        return (
           <footer className="footer">
               <div className="container">
                    <ul className="footer__list">
                        {footer.map(({ href, caption, click }, i) =>
                            <li className="footer__list-item" key={i} onClick={click}>
                                <a href={href} className="footer__list-link">{caption}</a>
                            </li>
                        )}
                        <li className="footer__list-item"><img src={telephone} alt="logo"/><a href="tel:+380 67 3772066" className="footer__list-link"> +380 67 3772066</a></li>
                        <li className="footer__list-item"><img src={envelope} alt="logo"/><a href="mailto:info@default-value.com" className="footer__list-link"> info@default-value.com</a></li>
                    </ul>
               <p className="footer__description">&#9400; {(new Date().getFullYear())} Default Value. All rights Reserved 
                </p>
               </div>
           </footer>
        )
    }
}

export default Footer;
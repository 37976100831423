import React, { Component } from "react";
import { Link } from "react-router-dom"
import ReactDOM from 'react-dom';

import "./header.scss";

import logo from "assets/img/logo.svg"


class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            isOpen: false,
            isModalOpen: false,
        };
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
        document.body.addEventListener('click', this.handleClick, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
        document.body.removeEventListener('click', this.handleClick, false);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    toggleMenu() {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    handleClick = e => {
        if(!ReactDOM.findDOMNode(this).contains(e.target)) {
            this.setState({
                isOpen: false
            })
        }
    }

    scrollToContact = () => {
        setTimeout(() => {
            document.querySelector(".contact-us").scrollIntoView({
                behavior: "smooth"
            })
        }, 30);
        this.handleMenuClose()
    };

    scrollToTop = () => {
        document.querySelector(".welcome-section__title").scrollIntoView({
            behavior: "smooth",
            block: "end"
        })
        this.handleMenuClose()
    };

    handleMenuClose = () => {
        this.setState({
            isOpen: false
        })
    }

    render() {
        const { width } = this.state;
        const isMobile = width <= 1024;
        const isHome = location.pathname === "/";
        const menu = [
            isMobile ? {href: "/", label: "Home", click: this.handleMenuClose} 
            : location.pathname !== "/" ? {href: "/", label: "Home"} 
            : {href: "/", label: "Home", click : this.scrollToTop},
            {href: "/how-to-upgrade", label: "How to update", click: () => this.props.history && this.props.history.push("/how-to-upgrade")},
            {href: "/about-us", label: "About us", click: this.handleMenuClose },
            {href: "/", label: "Contact us", click: this.scrollToContact },
        ];

        return (
            <header className="header">
                <div className="container">
                    <a href="/" className="logo-link"><img src={logo} alt="logo"/></a>
                    {isMobile ? <div className="burger-menu"
                        onClick={this.toggleMenu}>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                    </div>: ""}
                    <nav className={`nav-bar ${this.state.isOpen ? "show" : ""}`} tabIndex="0" onBlur={this.handleMenuClose}>
                        <ul className="nav-list">
                            {menu.map(({href, label, click}, i) =>
                                <li className="nav-item" key={i} onClick={click}>
                                    <Link to={href} className="nav-link">
                                        {label}
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </nav> 
                </div>
            </header>
        )
    }
}

export default Header;